<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-clipboard"></i> Precheck</h2>
        <div class="row listing">

            <div class="col-12">
                <div v-if="stats && stats.db" class="row mb-3">
                    <div class="col-4" >
                        <div v-if="stats.db.local" class="card mb-3">
                            <div class="card-body text-center">
                                <h4>Local Database</h4>
                                <ul class="list-group">
                                    <li class="list-group-item">DB Name: {{stats.db.local.db}}</li>
                                    <li class="list-group-item">Collections: {{stats.db.local.collections}}</li>
                                    <li class="list-group-item">Views: {{stats.db.local.views}}</li>
                                    <li class="list-group-item">Objects: {{stats.db.local.objects}}</li>
                                    <li class="list-group-item">Indexes: {{stats.db.local.indexes}}</li>
                                    <li class="list-group-item" :class="{'list-group-item-success': stats.db.local.good, 'list-group-item-warning': stats.db.local.warning, 'list-group-item-danger': stats.db.local.danger}">Storage Used: {{Number(stats.db.local.fsUsedSize/1024/1000).toFixed(2)}} GB</li>
                                    <li class="list-group-item" :class="{'list-group-item-success': stats.db.local.good, 'list-group-item-warning': stats.db.local.warning, 'list-group-item-danger': stats.db.local.danger}">Storage Total: {{Number(stats.db.local.fsTotalSize/1024/1000).toFixed(2)}} GB</li>
                                </ul>
                            </div>
                        </div>

                        <div v-if="stats.db.cache" class="card mb-3">
                            <div class="card-body text-center">
                                <h4>Cache Database</h4>
                                <ul class="list-group">
                                    <li class="list-group-item">DB Name: {{stats.db.cache.db}}</li>
                                    <li class="list-group-item">Collections: {{stats.db.cache.collections}}</li>
                                    <li class="list-group-item">Views: {{stats.db.cache.views}}</li>
                                    <li class="list-group-item">Objects: {{stats.db.cache.objects}}</li>
                                    <li class="list-group-item">Indexes: {{stats.db.cache.indexes}}</li>
                                    <li class="list-group-item" :class="{'list-group-item-success': stats.db.cache.good, 'list-group-item-warning': stats.db.cache.warning, 'list-group-item-danger': stats.db.cache.danger}">Storage Used: {{Number(stats.db.cache.fsUsedSize/1024/1000).toFixed(2)}} GB</li>
                                    <li class="list-group-item" :class="{'list-group-item-success': stats.db.cache.good, 'list-group-item-warning': stats.db.cache.warning, 'list-group-item-danger': stats.db.cache.danger}">Storage Total: {{Number(stats.db.cache.fsTotalSize/1024/1000).toFixed(2)}} GB</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div v-if="stats && stats.workers" class="col-12 col-sm-8">
                        <div v-if="stats.db.local" class="card mb-3">
                            <div class="card-body text-center">
                                <h4>Workers running over 24 hours</h4>
                                <table class="table">
                                    <tr>
                                        <th>Address</th>
                                        <th>CPU</th>
                                        <th>Memory</th>
                                        <th>Uptime</th>
                                    </tr>
                                    <tbody>
                                    <tr v-for="worker in stats.workers" :key="worker.fingerprint">
                                        <td><a :href="`http://${worker.ip}:${worker.port}/`" target="_blank">{{worker.ip}}:{{worker.port}}</a></td>
                                        <td>{{worker.stats.load[0]}}%</td>
                                        <td>{{Number(worker.stats.memory.free/1024/1024/1000).toFixed(0)}}/{{Number(worker.stats.memory.total/1024/1024/1000).toFixed(0)}}GB </td>
                                        <td>{{Number(worker.stats.uptime/86400).toFixed(0)}} days</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
//import swal from "sweetalert";

export default {
    name: "Precheck",
    props: ['user'],
    components: {

    },
    data: function () {
        return {
            stats: {db:{cache: null, local: null}, workers: []},
            preloader: false
        }
    },
    created: function(){
        this.load();

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    methods:{
        async load(){
            //Load the catchers
            var request = {'apikey': this.$root.apikey};

            this.preloader = false;
            this.$root.preloader = false;

            return axios.post(`${this.$root.serverUrl}/static/precheck/stats`, null, {params:request}).then(function(resp){
                this.stats = resp.data.data || {db:{cache:null, local:null}, workers:[]}
                if(this.stats.db && this.stats.db.local){
                    this.stats.db.local.percentage = (Number(this.stats.db.local.fsUsedSize) / Number(this.stats.db.local.fsTotalSize)) * 100;
                    this.stats.db.local.good = this.stats.db.local.percentage < 75 ;
                    this.stats.db.local.warning = this.stats.db.local.percentage >= 75 && this.stats.db.local.percentage < 90;
                    this.stats.db.local.danger = this.stats.db.local.percentage > 90 ;
                }
                if(this.stats.db && this.stats.db.cache){
                    this.stats.db.cache.percentage = (Number(this.stats.db.cache.fsUsedSize) / Number(this.stats.db.cache.fsTotalSize)) * 100;
                    this.stats.db.cache.good = this.stats.db.cache.percentage < 75 ;
                    this.stats.db.cache.warning = this.stats.db.cache.percentage >= 75 && this.stats.db.cache.percentage < 90;
                    this.stats.db.cache.danger = this.stats.db.cache.percentage > 90 ;
                }
                this.preloader = false;
            }.bind(this));
        }
    }
}
</script>

<style scoped>

</style>
